const locales = {
  en: {
    GAME_TITLE: "Games to learn geography!",
    GAME_SUB_TITLE_1: "An unknown place in the world",
    GAME_SUB_TITLE_2: "40 seconds to answer",
    GAME_SUB_TITLE_3: "Lots of fun (and learning)",
    GAME_WORLD_START_BUTTON: "WORLD",
    GAME_CITIES_START_BUTTON: "CITIES",
    GAME_WONDERS_START_BUTTON: "WONDERS",
    GAME_NEW_VERSION: "NEW VERSION",
    HOW_IT_WORKS: "How does it work?",
    HOW_IT_WORKS_1: "A map will appear in the screen and a countdown starts.",
    HOW_IT_WORKS_2:
      "The map zoom outs slowly giving you more information over time.",
    HOW_IT_WORKS_3: "After 10 seconds, 4 possible answers appear.",
    HOW_IT_WORKS_4: "Choose one!",
    HOW_IT_WORKS_5: "Repeat until you become the best player!",
    HOW_IT_WORKS_WONDERS_2: "The map zooms in slowly over time.",
    HOW_IT_WORKS_WONDERS_5:
      "There are 3 hints you can use. The hemisphere, the country flag and a picture. Hints can only be used once.",
    PLAY_BUTTON: "PLAY",
    TOP_100_PLAYERS: "Top 100 players (World)",
    TOP_100_PLAYERS_CITIES: "Top 100 players (Cities)",
    TOP_100_PLAYERS_WONDERS: "Top 100 players (Wonders)",
    LAST_PLAYER_RECORDS: "Last player records",
    CONTACT: "Contact",
    DONATION_1: "Help spread geography by making this app free forever.",
    DONATION_2: "or donate with Bitcoin",
    APP_SCORE: "Score",
    APP_YOUR_RECORD: "Your record",
    APP_KNOW_MORE:
      "Click here to know more about this country or anywhere else to go to the next map",
    APP_RETURN_TO: "Return to leaderboard",
    APP_NEW_RECORD: (position) =>
      `Good game! Your rank is ${position} of 100! Please enter your name`,
    APP_LEAVE_CONFIRM: "Do you want to leave the game?",
    APP_SHARE_RECORD: (score, position) =>
      `I scored ${score} on Zoomyear! I'm on the ${ordinalize(
        position
      )} position!`,
    APP_SHARE_RECORD_TITLE: "Share your record with the world!",
    APP_SHARE_RECORD_NO_THANKS: "No, thanks",
    NORTH_HEMISPHERE: "North hemisphere",
    SOUTH_HEMISPHERE: "South hemisphere",
    DID_YOU_KNOW: "Did you know?",
    FACT_CITY: "The capital city of the country is",
    FACT_CURRENCY: "The currency of the country is",
    FACT_ELEVATION: "The average elevation of the country is",
    CITIES_REGISTER_BUTTON: "REGISTER",
    CLOSED_BETA: "CLOSED BETA",
    NEW_GAME: "NEW GAME",
    LAUNCH_DATE: "Very soon",
  },
  es: {
    GAME_TITLE: "Juegos para aprender geografía!",
    GAME_SUB_TITLE_1: "Un lugar desconocido del mundo",
    GAME_SUB_TITLE_2: "40 segundos para responder",
    GAME_SUB_TITLE_3: "Montones de diversión (y aprendizaje)",
    GAME_WORLD_START_BUTTON: "WORLD",
    GAME_CITIES_START_BUTTON: "CITIES",
    GAME_WONDERS_START_BUTTON: "WONDERS",
    GAME_NEW_VERSION: "NUEVA VERSION",
    HOW_IT_WORKS: "Como funciona",
    HOW_IT_WORKS_1:
      "Un mapa aparecerá en la pantalla y empezará una cuenta atrás.",
    HOW_IT_WORKS_2:
      "El zoom del mapa disminuye lentamente dando más información a cada instante.",
    HOW_IT_WORKS_3: "Tras 15 segundos, 4 posibles opciones aparecen.",
    HOW_IT_WORKS_4: "Selecciona una!",
    HOW_IT_WORKS_5: "Repite hasta convertirte en el mejor jugador!",
    HOW_IT_WORKS_WONDERS_2: "El mapa se aproxima lentamente.",
    HOW_IT_WORKS_WONDERS_5:
      "Hay 3 pistas que puedes usar. Hemisferio, bandera del país e imagen. Cada pista solo puede ser usada una vez.",
    PLAY_BUTTON: "JUGAR",
    TOP_100_PLAYERS: "Mejores 100 jugadores (World)",
    TOP_100_PLAYERS_CITIES: "Mejores 100 jugadores (Cities)",
    TOP_100_PLAYERS_WONDERS: "Mejores 100 jugadores (Wonders)",
    LAST_PLAYER_RECORDS: "Últimos récords",
    CONTACT: "Contacto",
    DONATION_1:
      "Ayuda a difundir la geografía haciendo esta aplicación gratuita para siempre.",
    DONATION_2: "o dona con Bitcoin",
    APP_SCORE: "Puntos",
    APP_YOUR_RECORD: "Tu récord",
    APP_KNOW_MORE:
      "Haga clic aquí para saber más sobre este país o en cualquier otra parte para ir al siguiente mapa",
    APP_RETURN_TO: "Volver a la clasificación",
    APP_NEW_RECORD: (position) =>
      `Buena partida! Tu posición es ${position} de 100! Por favor, introduce tu nombre`,
    APP_LEAVE_CONFIRM: "Deseas salir del juego?",
    APP_SHARE_RECORD: (score, position) =>
      `Mi puntuación ha sido de ${score} en Zoomyear! Estoy en la posición ${position}!`,
    APP_SHARE_RECORD_TITLE: "Comparte tu puntuación con el mundo!",
    APP_SHARE_RECORD_NO_THANKS: "No, gracias",
    NORTH_HEMISPHERE: "Hemisferio norte",
    SOUTH_HEMISPHERE: "Hemisferio sur",
    DID_YOU_KNOW: "¿Sabias qué?",
    FACT_CITY: "La capital del país es",
    FACT_CURRENCY: "La moneda del país es",
    FACT_ELEVATION: "La altura media del país es",
    CITIES_REGISTER_BUTTON: "APÚNTATE",
    CLOSED_BETA: "BETA CERRADA",
    NEW_GAME: "NUEVO JUEGO",
    LAUNCH_DATE: "Muy pronto",
  },
  zho: {
    GAME_TITLE: "款学习地理的游戏！",
    GAME_SUB_TITLE_1: "世界上不为人知的地方",
    GAME_SUB_TITLE_2: "40秒后回答",
    GAME_SUB_TITLE_3: "非常有趣",
    GAME_WORLD_START_BUTTON: "WORLD",
    GAME_CITIES_START_BUTTON: "CITIES",
    GAME_WONDERS_START_BUTTON: "WONDERS",
    GAME_NEW_VERSION: "新版本",
    HOW_IT_WORKS: "工作原理",
    HOW_IT_WORKS_1: "屏幕上会出现一个地图，40秒倒计时开始。",
    HOW_IT_WORKS_2: "随着时间的推移，地图慢慢缩小，给你提供更多的信息。",
    HOW_IT_WORKS_3: "10秒之后，出现4个可能的答案",
    HOW_IT_WORKS_4: "选一个吧！",
    HOW_IT_WORKS_5: "一直重复直到你成为最棒的玩家！",
    HOW_IT_WORKS_WONDERS_2: "地图慢慢靠近",
    HOW_IT_WORKS_WONDERS_5:
      "您可以使用 3 个提示。 半球、国旗和图片。 提示只能使用一次。",
    PLAY_BUTTON: "PLAY",
    TOP_100_PLAYERS: "前100名玩家 (World)",
    TOP_100_PLAYERS_CITIES: "前100名玩家 (Cities)",
    TOP_100_PLAYERS_WONDERS: "前100名玩家 (Wonders)",
    LAST_PLAYER_RECORDS: "最后玩家记录",
    CONTACT: "联系方式",
    DONATION_1:
      "如果可以，请考虑捐款。它将帮助我们维护服务器的运行并促进未来的开发。",
    DONATION_2: "或者捐比特币",
    APP_SCORE: "得分",
    APP_YOUR_RECORD: "你的记录",
    APP_KNOW_MORE: "点击这里去到下一个地图了解更多关于这个国家或其他地方",
    APP_RETURN_TO: "回到排行榜",
    APP_NEW_RECORD: (position) => `真棒!你的排名是 ${position}! 请输入你的名字`,
    APP_LEAVE_CONFIRM: "你想退出比赛吗？",
    APP_SHARE_RECORD: (score, position) =>
      `我在动物游戏中得了 (${score})! 我在 ${position} 位置!`,
    APP_SHARE_RECORD_TITLE: "和大家分享你的记录！",
    APP_SHARE_RECORD_NO_THANKS: "不了，谢谢",
    NORTH_HEMISPHERE: "北半球",
    SOUTH_HEMISPHERE: "南半球",
    DID_YOU_KNOW: "你知道吗？",
    FACT_CITY: "该国的首都是",
    FACT_CURRENCY: "该国家的货币是",
    FACT_ELEVATION: "该国的平均海拔为",
    CITIES_REGISTER_BUTTON: "登记",
    CLOSED_BETA: "内测",
    NEW_GAME: "新游戏",
    LAUNCH_DATE: "快来了",
  },
};
